export const passwordConstants = {
    LOAD: 'password/LOAD',
    LOAD_SUCCESS: 'password/LOAD_SUCCESS',
    LOAD_FAIL: 'password/LOAD_FAIL',
    FLUSH: 'password/FLUSH',
    SAVE_USER: 'password/SAVE_USER',
    ALL_USER: 'password/ALL_USER',
    SUCCESS: 'password/SUCCESS',
    FLUSH_MESSAGES: 'password/FLUSH_MESSAGES',
};
